import { PMLColor } from '@pafcloud/style';

export const Colors = {
  Text: PMLColor.Lighter,
  MessageSeenText: PMLColor.Neutral,
  PaginationText: PMLColor.Lighter,

  BorderColor: PMLColor.Shadow,

  Highlight: `${PMLColor.LinkText}0D`, //5% opacity
  HighlightBorder: PMLColor.LinkText,
};
