import { graphql } from 'relay-runtime';
import { Icon } from '@pafcloud/base-components';
import { Color } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { useFormatAmount } from '@pafcloud/react-hook-utils';
import { getBonusOfferSteps, useHasActiveTurnoverStep } from '@pafcloud/contexts';
import { SafeQueryRenderer } from '@pafcloud/relay-helpers';
import { isType } from '@pafcloud/collection-utils';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type {
  DepositContributionQuery,
  DepositContributionQuery$data,
} from './__generated__/DepositContributionQuery.graphql';
import { InfoPanel, PanelContent, PanelFigure, Title } from './paf-payment-platform/form/panelStyle';
import { PanelGroup } from './paf-payment-platform/form/PanelGroup';
import { getDepositOfferData } from './getDepositOfferData';

const depositContributionQuery = graphql`
  query DepositContributionQuery {
    player {
      bonus {
        offers {
          status
          offerId
          ...useBonusOfferSteps_offer
        }
      }
    }
  }
`;

type Offers = NonNullable<NonNullable<NonNullable<DepositContributionQuery$data['player']>['bonus']>['offers']>[number];

const getDepositOfferMatchData = (activeDepositOffer: Offers) => {
  const depositStep = getDepositOfferData(activeDepositOffer);

  if (depositStep?.reward == null) {
    return null;
  }

  if (isType(depositStep.reward, 'MatchDepositBonusMoneyOfferReward')) {
    return {
      matchPercentage: depositStep.reward.matchPercentage,
      maxMatchAmount: depositStep.reward.maxMatchAmount,
    };
  }

  if (isType(depositStep.reward, 'BonusMoneyOfferReward')) {
    if ('minDepositAmount' in depositStep) {
      return {
        bonusAmount: depositStep.reward.bonusAmount,
        minDepositAmount: depositStep.minDepositAmount,
      };
    }
  }
};

export const DepositContribution = () => {
  const { t } = useTranslation(['deposit', 'bonus-info', 'bonus']);
  const formatAmount = useFormatAmount();
  const hasActiveTurnover = useHasActiveTurnoverStep();

  return (
    <SafeQueryRenderer<DepositContributionQuery>
      query={depositContributionQuery}
      fetchPolicy="store-or-network"
      render={({ props }) => {
        const offers = props.player?.bonus?.offers;

        if (offers == null) {
          return null;
        }

        const activeDepositOffersWithReward = offers.filter(
          (offer) =>
            offer.status === 'ACTIVE' &&
            getBonusOfferSteps(offer)?.some((step) => isType(step, 'DepositOfferStep') && step.reward != null),
        );

        if (activeDepositOffersWithReward.length === 0) {
          return null;
        }

        const activeTurnoverOffer = activeDepositOffersWithReward.filter((offer) =>
          getBonusOfferSteps(offer)?.some((step) => isType(step, 'TurnoverOfferStep')),
        );

        const activeDepositOfferExcludingTurnover = activeDepositOffersWithReward.filter(
          (offer) => !getBonusOfferSteps(offer)?.some((step) => isType(step, 'TurnoverOfferStep')),
        );

        if (activeTurnoverOffer.length === 0 && activeDepositOfferExcludingTurnover.length === 0) {
          return null;
        }

        const { matchPercentage, maxMatchAmount, bonusAmount, minDepositAmount } =
          getDepositOfferMatchData(activeTurnoverOffer[0] ?? activeDepositOfferExcludingTurnover[0]) ?? {};

        let translation: string | null = null;

        if (maxMatchAmount) {
          const maxMatchAmountWithCurrency = formatAmount(maxMatchAmount, { decimals: false });
          translation = t('deposit:match-deposit-percentage.text', {
            maxMatchAmountWithCurrency,
            matchPercentage: `${matchPercentage}%`,
          });
        } else if (bonusAmount && minDepositAmount) {
          const targetWithCurrency = formatAmount(bonusAmount, { decimals: false });
          const valueWithCurrency = formatAmount(minDepositAmount, { decimals: false });
          translation = t('bonus:deposit-for-bonus-money.title', {
            targetWithCurrency,
            valueWithCurrency,
          });
        }

        const showBonusText = !hasActiveTurnover && ((maxMatchAmount && matchPercentage) || bonusAmount);

        if (!showBonusText) {
          return null;
        }
        return (
          <PanelGroup title={t('bonus-info:title.bonus-info')}>
            <InfoPanel>
              <PanelFigure>
                <Icon
                  name="gift"
                  color={$buildEnv.theme === 'quirky' ? Color.Alternate : Color.Primary}
                  data-testid="bonus-icon"
                />
              </PanelFigure>
              <PanelContent>
                <Title>{translation}</Title>
              </PanelContent>
            </InfoPanel>
          </PanelGroup>
        );
      }}
    />
  );
};
