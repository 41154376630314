import { dispatchTrackingEvent } from '@pafcloud/tracking';
import type { TransactionMethod, TransactionDirection } from '../transaction';

type ChooseAmountEventData = {
  amount: number;
  type: TransactionDirection;
};

export const mapTransactionMethodTrackingData = (method?: TransactionMethod) => {
  return {
    name: method ? method.methodProvider.toLowerCase() : 'unknown',
    type: method ? method.methodType.toLowerCase() : 'unknown',
  };
};

export const dispatchTransactionAmountChosenTrackingEvent = ({ amount, type }: ChooseAmountEventData) => {
  const eventData = {
    step: { name: 'choose-amount', index: 1 },
    options: { amount: `${amount}` },
  } as const;

  if (type === 'DEPOSIT') {
    dispatchTrackingEvent('deposit-flow', { deposit: eventData });
  }
  if (type === 'WITHDRAWAL') {
    dispatchTrackingEvent('withdrawal-flow', { withdrawal: eventData });
  }
};

type ModifyAmountEventData = {
  amount: number;
  method?: TransactionMethod;
  type: TransactionDirection;
};

export const dispatchTransactionAmountModifiedTrackingEvent = ({ method, type, amount }: ModifyAmountEventData) => {
  const eventData = {
    step: { name: 'modify-amount', index: 2.5 },
    amount,
    method: mapTransactionMethodTrackingData(method),
  } as const;

  if (type === 'DEPOSIT') {
    dispatchTrackingEvent('deposit-flow', { deposit: eventData });
  }
  if (type === 'WITHDRAWAL') {
    dispatchTrackingEvent('withdrawal-flow', { withdrawal: eventData });
  }
};
