import { getBonusOfferSteps } from '@pafcloud/contexts';
import { isType } from '@pafcloud/collection-utils';
import type { DepositContributionQuery$data } from './__generated__/DepositContributionQuery.graphql';
import type { DepositOfferReminderQuery$data } from './__generated__/DepositOfferReminderQuery.graphql';

type Offers =
  | NonNullable<NonNullable<NonNullable<DepositContributionQuery$data['player']>['bonus']>['offers']>[number]
  | NonNullable<NonNullable<NonNullable<DepositOfferReminderQuery$data['player']>['bonus']>['offers']>[number];

export const getDepositOfferData = (activeDepositOffer: Offers) => {
  if (activeDepositOffer == null) {
    return null;
  }

  const activeDepositOfferSteps = getBonusOfferSteps(activeDepositOffer);

  if (activeDepositOfferSteps == null) {
    return null;
  }

  const depositStep = activeDepositOfferSteps.find(
    (step) => 'status' in step && step.status === 'ACTIVE' && isType(step, 'DepositOfferStep'),
  );

  return depositStep;
};
