import { useMemo } from 'react';
import { useWallet } from '@pafcloud/contexts';
import { useTranslation } from '@pafcloud/i18n';
import { lowercase } from '@pafcloud/util';
import { useFormatAmount } from '@pafcloud/react-hook-utils';
import type { TransactionDirection } from './transaction';

type Limit = {
  limit: number;
  getTranslation: () => string;
};

type Props = {
  minAmount: number;
  maxAmount: number;
  transactionType: TransactionDirection;
  yearlyLossLimit?: number;
  transactionLimitAmount?: number;
};

export const useGetMostRelevantLimitError = ({
  transactionType,
  minAmount,
  maxAmount,
  yearlyLossLimit,
  transactionLimitAmount,
}: Props) => {
  const { t } = useTranslation(lowercase(transactionType));
  const formatAmount = useFormatAmount();
  const wallet = useWallet();
  const availableFunds = wallet.cash ?? 0;

  const sortedLimits = useMemo(() => {
    const limits: Limit[] = [
      {
        limit: transactionLimitAmount ?? Number.MAX_SAFE_INTEGER,
        getTranslation: () => t('choose-amount.errors.over-limit'),
      },
      {
        limit: maxAmount,
        getTranslation: () => t('choose-amount.errors.above-type', { amount: formatAmount(maxAmount) }),
      },
    ];

    if (transactionType === 'WITHDRAWAL') {
      limits.push({ limit: availableFunds, getTranslation: () => t('choose-amount.errors.over-available-funds') });
    }

    if (yearlyLossLimit != null) {
      limits.push({
        limit: yearlyLossLimit,
        getTranslation: () =>
          t('choose-amount.exceeding.yearly-loss-limit', {
            amount: formatAmount(yearlyLossLimit),
            limitUrl: '/play-responsibly/yearly-loss-limit',
          }),
      });
    }

    return limits.sort((a, b) => a.limit - b.limit);
  }, [availableFunds, formatAmount, maxAmount, t, transactionLimitAmount, transactionType, yearlyLossLimit]);

  const getMostRelevantLimitError = (amount: number) => {
    if (yearlyLossLimit === 0) {
      return t('choose-amount.reached.yearly-loss-limit', { limitUrl: '/play-responsibly/yearly-loss-limit' });
    }

    if (amount < minAmount) {
      return t('choose-amount.errors.below-type', { amount: formatAmount(minAmount) });
    }

    const lowestLimit = sortedLimits.find((item) => amount > item.limit);

    return lowestLimit?.getTranslation() ?? null;
  };

  return getMostRelevantLimitError;
};
