import type { FC } from 'react';
import styled from '@emotion/styled';
import { useForm, FormProvider } from 'react-hook-form';
import { graphql, useFragment } from 'react-relay/hooks';
import { useEffect } from 'react';
import { Button } from '@pafcloud/base-components';
import { lowercase } from '@pafcloud/util';
import { useTranslation } from '@pafcloud/i18n';
import { useFlowRouter } from '@pafcloud/flow-router';
import { ChooseAmountInput } from '../ChooseAmountInput';
import { getDepositFormConfig } from '../deposit/DepositFormConfig';
import { QuickButtons } from '../deposit/QuickButtons';
import { TransactionError, TransactionErrorCode } from '../errors/transactionError';
import { findTransactionMethod, getTransactionMethodLogoUrl } from '../transaction';
import { dispatchTransactionAmountChosenTrackingEvent } from '../tracking/transactionAmountTrackingEvent';
import { getNumericAmount } from '../getNumericAmount';
import type { QuickDepositForm_content$key } from './__generated__/QuickDepositForm_content.graphql';
import { QuickDepositColors } from './quick-deposit-colors';

const contentFragment = graphql`
  fragment QuickDepositForm_content on Query {
    transactionMethods(type: DEPOSIT) {
      gateway
      methodProvider
      methodType
      minAmount
      maxAmount
      canAddAccount
      accounts {
        id
        name
        attributes {
          json
        }
        verificationStatus
        kycDocumentToken
      }
    }
    player {
      latestPaymentAccount {
        provider
      }
      netDepositLimit {
        totalDeposits
        netDepositLimit
      }
      depositLimit {
        daily {
          remainingAmount
        }
      }
    }
  }
`;
type QuickDepositFormContainerProps = {
  sidebar?: boolean;
};

const QuickDepositFormContainer = styled.div<QuickDepositFormContainerProps>(({ sidebar }) => ({
  margin: '24px auto',
  '--quick-button-text': sidebar ? QuickDepositColors.SidebarQuickButtons : 'initial',
  '--quick-button-text--hover': sidebar ? QuickDepositColors.SidebarQuickButtonsHover : 'initial',
  color: sidebar ? QuickDepositColors.SidebarText : 'inherit',
}));

const DepositForm = styled.form({
  width: '100%',
});

const SelectedMethodContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  gap: 16,
  marginTop: 12,
});

const ChangeMethodLink = styled.span({
  fontSize: 12,
  marginLeft: 3,
  whiteSpace: 'nowrap',
  color: 'var(--quick-button-text)',
  lineHeight: '36px',
  fontWeight: 'bold',
  cursor: 'pointer',
});

const SelectedMethodLogo = styled.img({
  height: 36,
  maxWidth: '50%',
  float: 'right',
});

type QuickDepositFormProps = {
  sidebar?: boolean;
  data: QuickDepositForm_content$key;
  initialAmount?: number;
  submit?: boolean;
  onClose?: () => void;
};

export const QuickDepositForm: FC<QuickDepositFormProps> = ({ initialAmount, sidebar, ...props }) => {
  const { transactionMethods, player } = useFragment(contentFragment, props.data);
  const { openFlow } = useFlowRouter();
  const formConfig = getDepositFormConfig();
  const { t } = useTranslation(['deposit', 'transaction-methods']);
  const latestPaymentAccount = player?.latestPaymentAccount;

  if (!transactionMethods || transactionMethods?.length === 0) {
    throw new TransactionError(TransactionErrorCode.NoMethodsAvailable);
  }
  const preSelectedProvider = latestPaymentAccount ? latestPaymentAccount.provider : null;
  const methodFromQueryParams = findTransactionMethod(transactionMethods, preSelectedProvider ?? '');

  const form = useForm<{ amount: string }>({
    mode: 'onChange',
    defaultValues: { amount: initialAmount?.toString() ?? formConfig.minDepositButtonValue.toString() },
  });

  const { register, handleSubmit, formState, trigger } = form;
  const { errors, touchedFields, dirtyFields, isValid } = formState;

  const minAmount =
    transactionMethods?.length > 0 ? Math.min(...transactionMethods.map((method) => method.minAmount)) : 0;
  const maxAmount =
    transactionMethods?.length > 0 ? Math.min(...transactionMethods.map((method) => method.maxAmount)) : 0;

  const transactionLimitAmount = player?.depositLimit?.daily.remainingAmount ?? undefined;

  const getYearlyLossLimit = () => {
    if (!player?.netDepositLimit?.netDepositLimit && !player?.netDepositLimit?.totalDeposits) {
      return undefined;
    }
    return player?.netDepositLimit?.netDepositLimit - player?.netDepositLimit?.totalDeposits;
  };

  const onSubmit = handleSubmit(({ amount }) => {
    const newAmount = getNumericAmount(amount);
    if (!newAmount) {
      return;
    }
    dispatchTransactionAmountChosenTrackingEvent({ amount: newAmount, type: 'DEPOSIT' });
    openFlow('deposit', { initialAmount: newAmount });
  });

  useEffect(() => {
    if (initialAmount != null) {
      trigger('amount');
    }
  }, [initialAmount, trigger]);

  return (
    <QuickDepositFormContainer sidebar={sidebar}>
      <QuickButtons
        onSelect={(amount) => openFlow('deposit', { initialAmount: amount })}
        depositLimit={transactionLimitAmount}
      />

      <FormProvider {...form}>
        <DepositForm method="POST" noValidate onSubmit={onSubmit}>
          <ChooseAmountInput
            register={register}
            transactionType="DEPOSIT"
            minAmount={minAmount}
            maxAmount={maxAmount}
            transactionLimitAmount={transactionLimitAmount}
            yearlyLossLimit={getYearlyLossLimit()}
            touched={touchedFields.amount}
            dirty={dirtyFields.amount}
            aria-describedby={errors.amount && 'choose-amount-form-amount-error-message'}
            error={errors.amount}
            keepFullWidth={true}
          >
            <Button type="submit" size="small" disabled={!isValid}>
              {t('choose-amount.button.text')}
            </Button>
          </ChooseAmountInput>
          {latestPaymentAccount && preSelectedProvider && (
            <SelectedMethodContainer>
              <SelectedMethodLogo
                src={
                  sidebar
                    ? `/static/images/payment-providers/${lowercase(preSelectedProvider)}-sidebar.svg`
                    : getTransactionMethodLogoUrl((methodFromQueryParams ?? transactionMethods[0]).methodProvider)
                }
                alt={t(`choose-method.image-alt`)}
              />
              <ChangeMethodLink
                onClick={() =>
                  openFlow('deposit', {
                    initialAmount: Number(form.getValues().amount),
                    changeMethod: true,
                  })
                }
              >
                {t('choose-method.button.change')}
              </ChangeMethodLink>
            </SelectedMethodContainer>
          )}
        </DepositForm>
      </FormProvider>
    </QuickDepositFormContainer>
  );
};
