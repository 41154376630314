import type { FC } from 'react';
import { graphql } from 'relay-runtime';
import styled from '@emotion/styled';
import { SafeQueryRenderer } from '@pafcloud/relay-helpers';
import { getBonusOfferSteps } from '@pafcloud/contexts';
import { isType } from '@pafcloud/collection-utils';
import { Icon } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { useFormatAmount } from '@pafcloud/react-hook-utils';
import { useFlowRouter } from '@pafcloud/flow-router';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Color } from '@pafcloud/style';
import { getDepositOfferData } from './getDepositOfferData';
import { PanelGroup } from './paf-payment-platform/form/PanelGroup';
import { InfoPanel, PanelContent, PanelFigure, Title } from './paf-payment-platform/form/panelStyle';
import type {
  DepositOfferReminderQuery,
  DepositOfferReminderQuery$data,
} from './__generated__/DepositOfferReminderQuery.graphql';

const depositOfferReminderQuery = graphql`
  query DepositOfferReminderQuery {
    player {
      bonus {
        offers {
          status
          offerId
          ...useBonusOfferSteps_offer
        }
      }
    }
  }
`;

const InfoButton = styled.button({
  padding: 0,
  border: 'none',
  background: 'transparent',
  color: 'inherit',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  cursor: 'pointer',
});

type Offers = NonNullable<
  NonNullable<NonNullable<DepositOfferReminderQuery$data['player']>['bonus']>['offers']
>[number];

const getDepositOfferMinDepositAmountData = (activeDepositOffer: Offers) => {
  const depositStep = getDepositOfferData(activeDepositOffer);

  if (depositStep != null && 'minDepositAmount' in depositStep) {
    return {
      minDepositAmount: depositStep.minDepositAmount,
    };
  }
};

type Props = {
  numericDepositAmount: number;
};

export const DepositOfferReminder: FC<Props> = ({ numericDepositAmount }) => {
  const { t } = useTranslation(['deposit', 'bonus-info']);
  const formatAmount = useFormatAmount();
  const { openFlow } = useFlowRouter();

  return (
    <SafeQueryRenderer<DepositOfferReminderQuery>
      query={depositOfferReminderQuery}
      fetchPolicy="store-or-network"
      render={({ props }) => {
        const offers = props.player?.bonus?.offers;

        if (offers == null) {
          return null;
        }

        const activeDepositOffers = offers.filter(
          (offer) =>
            offer.status === 'ACTIVE' &&
            getBonusOfferSteps(offer)?.some((step) => isType(step, 'DepositOfferStep') && step.status === 'ACTIVE'),
        );

        if (activeDepositOffers.length === 0) {
          return null;
        }

        const activeTurnoverOffer = activeDepositOffers.filter((offer) =>
          getBonusOfferSteps(offer)?.some((step) => isType(step, 'TurnoverOfferStep')),
        );

        // If we have an active offer with a turnover step we do not need to show the deposit offer reminder.
        // The turnover offers are already getting handled in DepositContribution.
        if (activeTurnoverOffer.length > 0) {
          return null;
        }

        // The actual deposit will always count against the oldest active deposit offer.
        // So when we have more than one active, we need to get the oldest one and get the minDepositAmount from it.
        const oldestActiveDepositOffer = activeDepositOffers[activeDepositOffers.length - 1];
        const currentDepositOffer = oldestActiveDepositOffer ?? activeDepositOffers[0];

        const { minDepositAmount } = getDepositOfferMinDepositAmountData(currentDepositOffer) ?? {};

        const hideDepositOfferReminder = minDepositAmount == undefined || numericDepositAmount >= minDepositAmount;

        if (hideDepositOfferReminder) {
          return null;
        }

        const minDepositAmountWithCurrency = minDepositAmount
          ? formatAmount(minDepositAmount, { decimals: false })
          : null;

        return (
          <PanelGroup title={t('bonus-info:title.bonus-info')}>
            <InfoButton
              role="link"
              aria-label={t('reminder-minimum-deposit-amount.link.aria-label')}
              onClick={() => openFlow('bonus', { playerProgramId: currentDepositOffer.offerId })}
            >
              <InfoPanel>
                <PanelFigure>
                  <Icon
                    name="gift"
                    color={$buildEnv.theme === 'quirky' ? Color.Alternate : Color.Primary}
                    data-testid="bonus-icon"
                  />
                </PanelFigure>
                <PanelContent>
                  <Title>
                    {t('reminder-minimum-deposit-amount.text', {
                      minDepositAmount: minDepositAmountWithCurrency,
                    })}
                  </Title>
                </PanelContent>
              </InfoPanel>
            </InfoButton>
          </PanelGroup>
        );
      }}
    />
  );
};
