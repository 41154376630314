import { Color } from '@pafcloud/style';

const AmountField = {
  Background: Color.Surface.Nested.Background + '80',
  BackgroundFocused: Color.Surface.Nested.Background,
  Label: Color.BodyText,
  PlaceholderText: `${Color.MutedText}80`,
  CurrencySymbol: Color.MutedText,
};

const Panel = {
  Background: 'transparent',
  InfoBackground: `${Color.Primitive.Primary}20`,
  Border: `2px solid ${Color.Surface.Floating.Dimmed}`,
  BorderFocus: `2px solid ${Color.Primitive.Accent}`,
  Title: Color.BodyText,
  Description: Color.MutedText,
  Arrow: Color.MutedText,

  RadioButton: Color.Surface.Floating.Dimmed,
  RadioButtonChecked: Color.Primitive.Accent,
  RadioButtonTick: Color.Primitive.AccentContrast,

  DropdownBackground: Color.Surface.Floating.Background,
  DropdownShadow: Color.Elevation.Level2,
  OptionTickSelected: Color.Primitive.Accent,
  OptionBackgroundHover: `${Color.Primitive.Accent}20`,
};

export const Colors = {
  BorderRadius: Color.CornerRadius.Base,
  FormError: Color.Signal.Danger,
  FormOK: Color.Signal.Success,
  BorderColor: Color.Surface.Floating.Dimmed,
  StrongText: Color.HeadingText,
  Text: Color.BodyText,

  AmountField,
  Panel,
};
