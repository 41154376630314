import { PMLColor } from '@pafcloud/style';

export const Colors = {
  Text: PMLColor.Primary,
  TextHover: PMLColor.PrimaryHighlight,
  TextActive: PMLColor.PrimaryHighlight,
  TextShadow: `0 1px ${PMLColor.DarkerTransparent20}`,

  Icon: PMLColor.Primary,
  IconActive: PMLColor.PrimaryHighlight,

  Background: undefined,
  BackgroundHover: PMLColor.DarkerTransparent20,
  BackgroundActive: `linear-gradient(90deg, ${PMLColor.AlternateTransparent10} 0%, ${PMLColor.Background}00 50%, transparent 80% )`,

  ItemDivider: undefined,
  ItemActiveShadow: undefined,

  SubMenuBackground: PMLColor.DarkerTransparent20,
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: 'uppercase',
  Weight: 'bold',
  WeightHover: 'bold',
  WeightActive: 'bold',
} as const;
