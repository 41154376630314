import styled from '@emotion/styled';
import type React from 'react';
import { Heading } from '@pafcloud/base-components';
import { Color, FontTextSize } from '@pafcloud/style';
import { PPPColors } from '../paf-payment-platform-colors';

export type PanelProps = {
  description?: string;
  title: string;
  figure?: React.ReactNode;
  onClick?: () => void;
};

export const PanelHeaderStyle = styled.header({
  lineHeight: 1.2,
});

export const PanelHeading = styled(Heading)({
  margin: 0,
  fontSize: FontTextSize.Small,
  color: PPPColors.Panel.Title,
});

export const PanelDescription = styled.p({
  margin: '4px 0 0 0',
  fontSize: FontTextSize.Small,
  color: PPPColors.Panel.Description,
});

// Used to group multiple panels together
export const PanelGroupStyle = styled.section({
  display: 'grid',
  gridAutoFlow: 'row',
  gap: 8,
});

export const Panel = styled.div({
  position: 'relative',
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 'auto 1fr',
  alignItems: 'center',
  justifyItems: 'start',
  gap: 12,
  width: '100%',
  minHeight: 64,
  padding: 16,
  border: PPPColors.Panel.Border,
  borderRadius: PPPColors.BorderRadius,
  background: PPPColors.Panel.Background,
  textAlign: 'left',
  outline: 'none',

  '&[role="button"], &[role="radio"]': {
    cursor: 'pointer',
  },

  // TODO add focus styles
  ':focus-within, :focus-visible': {
    border: PPPColors.Panel.BorderFocus,
  },
});

export const InfoPanel = styled(Panel)({
  border: 0,
  background: PPPColors.Panel.InfoBackground,
});

export const PanelFigure = styled.figure({
  display: 'grid',
  placeItems: 'center',
  width: 56,
  height: 'auto',
  margin: 0,
  borderRadius: 3,
  boxShadow: Color.Elevation.Level1,
  background: 'white',
  aspectRatio: '16/9',

  img: {
    display: 'flex',
    maxWidth: '100%',
    // use border instead of margin to prevent box-sizing issues
    border: 'solid 4px transparent',
    objectFit: 'contain',
  },
});

export const PanelContent = styled.div({
  display: 'grid',
  gridTemplateRows: 'repeat(2, auto)',
  width: '100%',

  fontSize: FontTextSize.Normal,
  lineHeight: 1,
});

export const Title = styled.strong({
  fontSize: FontTextSize.Normal,
  color: PPPColors.Panel.Title,
});

export const Info = styled.span({
  marginTop: 2,
  fontSize: FontTextSize.Small,
  color: PPPColors.Panel.Description,
});

export const PanelAction = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  justifySelf: 'end', // align action to the right when no figure is present
  height: 24,
  color: PPPColors.Panel.Arrow,
});
