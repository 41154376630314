import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_default } from './colors.default';
import { Colors as Colors_quirky } from './colors.quirky';

export const PPPColors = (() => {
  if ($buildEnv.theme === 'quirky') {
    return Colors_quirky;
  }

  return Colors_default;
})();
