/**
 * @generated SignedSource<<5536fa4bd6300c8b7736745bdc59191f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SidebarGolden_content$data = {
  readonly site: {
    readonly menu: {
      readonly search: {
        readonly gameListId: string | null;
      };
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SidebarVerificationSection_content">;
  readonly " $fragmentType": "SidebarGolden_content";
};
export type SidebarGolden_content$key = {
  readonly " $data"?: SidebarGolden_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"SidebarGolden_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarGolden_content",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SidebarVerificationSection_content"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Menu",
          "kind": "LinkedField",
          "name": "menu",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuSearch",
              "kind": "LinkedField",
              "name": "search",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "gameListId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2c5b0e68adaac0c9b8721fe4f26baf1f";

export default node;
