/**
 * @generated SignedSource<<5ee587280c38dd66f2c02e0d3b3e9124>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SidebarGoldenQuery$variables = Record<PropertyKey, never>;
export type SidebarGoldenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"QuickDepositForm_content">;
};
export type SidebarGoldenQuery = {
  response: SidebarGoldenQuery$data;
  variables: SidebarGoldenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SidebarGoldenQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "QuickDepositForm_content"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SidebarGoldenQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "DEPOSIT"
          }
        ],
        "concreteType": "TransactionMethod",
        "kind": "LinkedField",
        "name": "transactionMethods",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gateway",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "methodProvider",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "methodType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canAddAccount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TransactionAccount",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Json",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "json",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "verificationStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kycDocumentToken",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "transactionMethods(type:\"DEPOSIT\")"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "player",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentAccount",
            "kind": "LinkedField",
            "name": "latestPaymentAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "provider",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NetDepositLimit",
            "kind": "LinkedField",
            "name": "netDepositLimit",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalDeposits",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "netDepositLimit",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DepositLimits",
            "kind": "LinkedField",
            "name": "depositLimit",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DepositLimit",
                "kind": "LinkedField",
                "name": "daily",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remainingAmount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40a21e6ef182a94a51b2eb2cedf90c50",
    "id": null,
    "metadata": {},
    "name": "SidebarGoldenQuery",
    "operationKind": "query",
    "text": "query SidebarGoldenQuery {\n  ...QuickDepositForm_content\n}\n\nfragment QuickDepositForm_content on Query {\n  transactionMethods(type: DEPOSIT) {\n    gateway\n    methodProvider\n    methodType\n    minAmount\n    maxAmount\n    canAddAccount\n    accounts {\n      id\n      name\n      attributes {\n        json\n      }\n      verificationStatus\n      kycDocumentToken\n    }\n  }\n  player {\n    latestPaymentAccount {\n      provider\n    }\n    netDepositLimit {\n      totalDeposits\n      netDepositLimit\n    }\n    depositLimit {\n      daily {\n        remainingAmount\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4fbdec4533598076b7856fc94ead5a84";

export default node;
