/**
 * @generated SignedSource<<ddc1f4a856a9e4a010d97029a206d856>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountMethodProvider = "AKTIA" | "ALANDSBANKEN" | "BRITE_SE" | "DANSKEBANK" | "HANDELSBANKEN" | "NORDEA" | "NORDEA_SIIRTO" | "OMASAASTOPANKKI" | "OP" | "OP_CORP_IBAN" | "POPPANKKI" | "SAASTOPANKKI" | "SKRILL" | "SPANKKI" | "SWISH" | "TRUSTLY";
export type TransactionAccountVerificationStatus = "IN_PROGRESS" | "NOT_REQUESTED" | "REQUESTED";
export type TransactionGateway = "PIQ" | "PPS";
export type TransactionMethodProvider = "AKTIA" | "ALANDSBANKEN" | "APLAUZ" | "APPLEPAY" | "BANK" | "BANKIBAN" | "BIZUM" | "BRITE" | "CARD" | "CITADELE" | "COOP" | "DANSKEBANK" | "HANDELSBANKEN" | "LHV" | "LUMINOR" | "MOBILEPAY" | "MUCHBETTER" | "NEOPAY" | "NETELLER" | "NORDEA" | "OMASAASTOPANKKI" | "OP" | "OPCORPIBAN" | "PAYPAL" | "PAYSAFECARD" | "PAYSERA" | "POPPANKKI" | "POSTFINANCE_CARD" | "POSTFINANCE_E_FINANCE" | "POSTFINANCE_PAY" | "REVOLUT" | "SAASTOPANKKI" | "SEB" | "SIIRTO" | "SKRILL" | "SPANKKI" | "SWEDBANK" | "SWEDBANKEXPRESS" | "SWISH" | "TRUSTLY" | "TWINT" | "UNKNOWN" | "WEBREDIRECT_GENERIC" | "WISE" | "YAPEAL";
export type TransactionMethodType = "BANK" | "BANKIBAN" | "CARD" | "EWALLET" | "MOBILE" | "UNKNOWN" | "WEBREDIRECT";
import { FragmentRefs } from "relay-runtime";
export type QuickDepositForm_content$data = {
  readonly player: {
    readonly depositLimit: {
      readonly daily: {
        readonly remainingAmount: number | null;
      };
    } | null;
    readonly latestPaymentAccount: {
      readonly provider: AccountMethodProvider;
    } | null;
    readonly netDepositLimit: {
      readonly netDepositLimit: number;
      readonly totalDeposits: number;
    } | null;
  } | null;
  readonly transactionMethods: ReadonlyArray<{
    readonly accounts: ReadonlyArray<{
      readonly attributes: {
        readonly json: string | null;
      };
      readonly id: string | null;
      readonly kycDocumentToken: string | null;
      readonly name: string;
      readonly verificationStatus: TransactionAccountVerificationStatus;
    }>;
    readonly canAddAccount: boolean;
    readonly gateway: TransactionGateway;
    readonly maxAmount: number;
    readonly methodProvider: TransactionMethodProvider;
    readonly methodType: TransactionMethodType;
    readonly minAmount: number;
  }>;
  readonly " $fragmentType": "QuickDepositForm_content";
};
export type QuickDepositForm_content$key = {
  readonly " $data"?: QuickDepositForm_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuickDepositForm_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuickDepositForm_content",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "DEPOSIT"
        }
      ],
      "concreteType": "TransactionMethod",
      "kind": "LinkedField",
      "name": "transactionMethods",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gateway",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "methodProvider",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "methodType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canAddAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionAccount",
          "kind": "LinkedField",
          "name": "accounts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Json",
              "kind": "LinkedField",
              "name": "attributes",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "json",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "verificationStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kycDocumentToken",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "transactionMethods(type:\"DEPOSIT\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Player",
      "kind": "LinkedField",
      "name": "player",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentAccount",
          "kind": "LinkedField",
          "name": "latestPaymentAccount",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "provider",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NetDepositLimit",
          "kind": "LinkedField",
          "name": "netDepositLimit",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalDeposits",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "netDepositLimit",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DepositLimits",
          "kind": "LinkedField",
          "name": "depositLimit",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DepositLimit",
              "kind": "LinkedField",
              "name": "daily",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "remainingAmount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "322306dc8f3127b9ed88098248d86b15";

export default node;
