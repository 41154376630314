import type { ReactNode, FC } from 'react';
import styled from '@emotion/styled';
import { Color, Font, FontTextSize } from '@pafcloud/style';
import { NoStyleButton } from '../button';
import { getNotificationBadgeSeverityColor } from '../notification-badge';
import type { NotificationBadgeSeverity } from '../notification-badge';
import { Icon } from '../icon';
import { MarkdownParser } from '../markdown-parser';

const AlertContainer = styled.section<{ severity: NotificationBadgeSeverity }>(({ severity }) => ({
  backgroundColor: Color.Surface.Base.Background,
  boxShadow: Color.Elevation.Level2,
  margin: '24px auto',
  width: '90%',
  borderRadius: 4,
  position: 'relative',

  '::before': {
    content: '""',
    display: 'block',
    background: getNotificationBadgeSeverityColor(severity).Background,
    height: 6,
  },
}));

const AlertContent = styled.div({
  padding: 16,
});

const AlertIcon = styled(Icon)<{ severity: NotificationBadgeSeverity }>(({ severity }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'translate(-45%, -35%)',
  padding: 8,
  borderRadius: '50%',
  background: getNotificationBadgeSeverityColor(severity).Background,
  color: getNotificationBadgeSeverityColor(severity).Text,
}));

const Flex = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',

  button: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: FontTextSize.Normal,
  },
});

const Title = styled.strong({
  display: 'block',
  color: Color.HeadingText,
  fontSize: FontTextSize.Huge,
  fontFamily: Font.Heading,
});

const Message = styled.p({
  color: Color.BodyText,
  fontSize: FontTextSize.Normal,
  lineHeight: 1.5,
  margin: 0,
});

type SharedAlertProps = {
  severity: NotificationBadgeSeverity;
  title?: string;
  message: string;
};

type StaticAlertProps = SharedAlertProps & {
  type: 'static';
};

type InteractiveAlertProps = SharedAlertProps & {
  type: 'interactive';
  buttonText: string;
  buttonAction: () => void;
  buttonAsLink?: boolean;
  extraButton?: ReactNode;
};

type AlertProps = StaticAlertProps | InteractiveAlertProps;

const AlertChildren: FC<Omit<StaticAlertProps, 'type'>> = ({ severity, message, title }) => (
  <>
    <AlertIcon
      name={severity === 'low' ? 'check' : 'exclamation'}
      size={16}
      width={16}
      severity={severity}
      data-testid={severity}
    />
    {title && <Title>{title}</Title>}

    {typeof message === 'object' ? message : <MarkdownParser components={{ p: Message }}>{message}</MarkdownParser>}
  </>
);

export const Alert: FC<AlertProps> = (props) => {
  const { message, title, severity } = props;

  if (props.type === 'interactive') {
    return (
      <AlertContainer role="alertdialog" severity={severity}>
        <AlertContent>
          <AlertChildren message={message} title={title} severity={severity} />
          <Flex>
            {props.extraButton}
            <NoStyleButton onClick={props.buttonAction} role={props.buttonAsLink ? 'link' : undefined}>
              {props.buttonText}
            </NoStyleButton>
          </Flex>
        </AlertContent>
      </AlertContainer>
    );
  }

  return (
    <AlertContainer role="alert" severity={severity}>
      <AlertContent>
        <AlertChildren message={message} title={title} severity={severity} />
      </AlertContent>
    </AlertContainer>
  );
};
