import type { FC } from 'react';
import styled from '@emotion/styled';
import type { FieldError, UseFormRegister, Validate } from 'react-hook-form';
import type { FormInputProps } from '@pafcloud/form-components';
import { FormErrorText, FormInput, FormInputSuffix } from '@pafcloud/form-components';
import { MarkdownParser } from '@pafcloud/base-components';
import { getCurrencyCode, getCurrencySymbol } from '@pafcloud/locale';
import { lowercase } from '@pafcloud/util';
import { Breakpoint, FontTextSize } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { useFormatAmount } from '@pafcloud/react-hook-utils';
import { useWallet } from '@pafcloud/contexts';
import type { TransactionDirection } from './transaction';
import { TaxWarning } from './TaxWarning';
import { useGetMostRelevantLimitError } from './useGetMostRelevantError';
import { DepositContribution } from './DepositContribution';
import { calculateTaxation } from './taxation';
import { getNumericAmount } from './getNumericAmount';
import { DepositOfferReminder } from './DepositOfferReminder';

const CenterErrorText = styled(FormErrorText)({
  textAlign: 'center',
});

const InputContainer = styled.div<{ keepFullWidth?: boolean }>(({ keepFullWidth }) => ({
  textAlign: 'center',
  margin: '16px auto',
  [Breakpoint.TabletOrLarger]: {
    maxWidth: keepFullWidth ? '100%' : '64%',
  },
}));

const TransactionLimit = styled.small({
  display: 'block',
  fontSize: FontTextSize.Small,
  marginTop: 10,
  textAlign: 'center',
});

const MAX_NUMERIC_LENGTH = String(Number.MAX_SAFE_INTEGER).length;

type Props = Omit<FormInputProps, 'error' | 'ref'> & {
  register: UseFormRegister<{ amount: string }>;
  inputAmount?: string;
  transactionType: TransactionDirection;
  yearlyLossLimit?: number;
  minAmount: number;
  maxAmount: number;
  transactionLimitAmount?: number;
  error?: FieldError;
  keepFullWidth?: boolean;
};

const ButtonContainer = styled.div({
  zIndex: 1,

  button: {
    minWidth: '5rem',
    marginRight: 8,

    img: {
      maxHeight: '1.5rem',
    },
  },
});

const BonusInfo = styled.div({
  margin: '32px 0',
});

export const ChooseAmountInput: FC<Props> = ({
  register,
  inputAmount,
  transactionType,
  minAmount,
  maxAmount,
  transactionLimitAmount,
  yearlyLossLimit,
  error,
  keepFullWidth,
  ...inputProps
}) => {
  const { t } = useTranslation(lowercase(transactionType));
  const formatAmount = useFormatAmount();
  const wallet = useWallet();
  const availableFunds = wallet.cash ?? 0;

  const getMostRelevantLimitError = useGetMostRelevantLimitError({
    transactionType,
    minAmount,
    maxAmount,
    yearlyLossLimit,
    transactionLimitAmount,
  });

  const showTaxWarning = $buildEnv.market === 'latvia' && transactionType === 'WITHDRAWAL';

  const validate: Validate<string> = (value) => {
    const amount = getNumericAmount(value);

    if (amount == null) {
      return false;
    }

    // The tax warning is a blocker for withdrawals, so we can skip the rest of the validation.
    if (showTaxWarning && calculateTaxation(amount) > 0) {
      return false;
    }

    const errorMessage = getMostRelevantLimitError(amount);

    return errorMessage ?? true;
  };

  const showAvailableFunds = transactionLimitAmount == null && transactionType === 'WITHDRAWAL';
  const showWithdrawalLimit = transactionLimitAmount != null && transactionType === 'WITHDRAWAL';
  const showYearlyLossLimit = transactionLimitAmount == null && yearlyLossLimit != null && yearlyLossLimit < maxAmount;
  const perDepositWillApply = transactionLimitAmount == null || maxAmount < transactionLimitAmount;
  const numericDepositAmount = inputAmount != null ? getNumericAmount(inputAmount) : null;

  return (
    <>
      <InputContainer keepFullWidth={keepFullWidth}>
        <FormInput
          aria-required
          {...register('amount', {
            validate,
            pattern: {
              value: /^[0-9]+([,.][0-9]{1,2})?$/,
              message: t('choose-amount.errors.invalid-format'),
            },
            required: t('choose-amount.errors.required'),
          })}
          inputMode="decimal"
          autoFocus
          maxLength={MAX_NUMERIC_LENGTH}
          autoComplete="off"
          floatingLabel={false}
          error={Boolean(error)}
          label={t('choose-amount.label')}
          {...inputProps}
        >
          {inputProps.children ? (
            <ButtonContainer>{inputProps.children}</ButtonContainer>
          ) : (
            <FormInputSuffix>{getCurrencySymbol(getCurrencyCode())}</FormInputSuffix>
          )}
        </FormInput>
      </InputContainer>

      {error?.message && <MarkdownParser components={{ p: CenterErrorText }}>{error.message}</MarkdownParser>}
      {showTaxWarning && <TaxWarning />}

      {transactionLimitAmount != null && transactionType === 'DEPOSIT' && !perDepositWillApply && (
        <MarkdownParser components={{ p: TransactionLimit }}>
          {t('choose-amount.limit', { amount: formatAmount(transactionLimitAmount) })}
        </MarkdownParser>
      )}

      {transactionType === 'DEPOSIT' && showYearlyLossLimit && (
        <MarkdownParser components={{ p: TransactionLimit }}>
          {t('choose-amount.limit-per-deposit', { amount: formatAmount(yearlyLossLimit) })}
        </MarkdownParser>
      )}

      {transactionType === 'DEPOSIT' && perDepositWillApply && !showYearlyLossLimit && (
        <MarkdownParser components={{ p: TransactionLimit }}>
          {t('choose-amount.limit-per-deposit', { amount: formatAmount(maxAmount) })}
        </MarkdownParser>
      )}

      {showWithdrawalLimit && (
        <MarkdownParser components={{ p: TransactionLimit }}>
          {t('choose-amount.withdrawal.total', { amount: formatAmount(transactionLimitAmount) })}
        </MarkdownParser>
      )}

      {showAvailableFunds && (
        <MarkdownParser components={{ p: TransactionLimit }}>
          {t('choose-amount.withdrawal.funds', { amount: formatAmount(availableFunds) })}
        </MarkdownParser>
      )}

      {transactionType === 'DEPOSIT' && numericDepositAmount != null && (
        <BonusInfo>
          <DepositOfferReminder numericDepositAmount={numericDepositAmount} />
        </BonusInfo>
      )}

      {transactionType === 'DEPOSIT' && (
        <BonusInfo>
          <DepositContribution />
        </BonusInfo>
      )}
    </>
  );
};
