import type { FC, ReactNode } from 'react';
import { PanelDescription, PanelHeaderStyle, PanelHeading, PanelGroupStyle } from './panelStyle';

type PanelGroupProps = {
  title?: string;
  description?: string;
  children?: ReactNode;
};

export const PanelGroup: FC<PanelGroupProps> = ({ title, description, children }) => {
  if (!title && !description) {
    return <PanelGroupStyle>{children}</PanelGroupStyle>;
  }

  return (
    <PanelGroupStyle>
      <PanelHeaderStyle>
        {title && <PanelHeading>{title}</PanelHeading>}
        {description && <PanelDescription>{description}</PanelDescription>}
      </PanelHeaderStyle>

      {children}
    </PanelGroupStyle>
  );
};
