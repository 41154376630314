import type { TransactionMethod, TransactionDirection } from '../transaction';

export enum TransactionErrorCode {
  Cancelled = 'Cancelled',
  BeneficialOwnershipConfirmation = 'Beneficial ownership confirmation failed',
  NoMethodsAvailable = 'No transaction methods available',
  ShouldMakeDepositFirst = 'Should make deposit first',
  UnableToProcessTransaction = 'Could not process transaction',
  SowRequiredError = 'Source of wealth required',
  UnableToLogin = 'Unable to login',
  Unknown = 'Unknown error',
}

export type TransactionErrorData = {
  type: TransactionDirection;
  amount?: number;
  method?: TransactionMethod;
  reference?: string;
  firstTransaction?: boolean;
  error?: string;
  message?: string;
};

export class TransactionError extends Error {
  constructor(
    public errorCode: TransactionErrorCode,
    public data?: TransactionErrorData,
  ) {
    super(`Unable to process transaction. ${errorCode} ${data?.error ?? ''}`);
  }

  static from(error: Error): TransactionError {
    if (error instanceof TransactionError) {
      return error;
    }
    return new TransactionError(TransactionErrorCode.Unknown);
  }
}

export class PayAndPlayError extends TransactionError {
  constructor(
    public override errorCode: TransactionErrorCode,
    data?: Omit<TransactionErrorData, 'type'>,
  ) {
    super(errorCode, { ...data, type: 'DEPOSIT' });
  }
}
