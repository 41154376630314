import styled from '@emotion/styled';
import type { FC } from 'react';
import { forwardRef } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { ZIndex, LayoutProps, FontTextSize, Breakpoint, DynamicValues } from '@pafcloud/style';
import { Button, ButtonLink, Heading, Icon, Image, Link, Logo, ProgressBar } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { useIsLoggedIn, useWallet, useBonus, useConfig } from '@pafcloud/contexts';
import { useFlowRouter } from '@pafcloud/flow-router';
import { SafeQueryRenderer } from '@pafcloud/relay-helpers';
import { DepositToPlayForm } from '../../../../transaction/deposit/DepositToPlayForm';
import { Burger } from '../../../header-content/HeaderContent.golden';
import { WalletComponent } from '../../../../wallet-component';
import { BonusbarContainer } from '../../../../bonus';
import { HeaderIconButton } from '../../../header-icon-button';
import { SidebarSearch } from '../sidebar-search';
import { QuickDepositForm } from '../../../../transaction/quick-deposit';
import { SidebarVerificationSection } from '../sidebar-verification-section';
import type { SidebarGoldenQuery } from './__generated__/SidebarGoldenQuery.graphql';
import { useStoredState } from './useStoredState';
import { SidebarColors } from './sidebar-colors';
import type { SidebarProps } from './Sidebar';

const contentFragment = graphql`
  fragment SidebarGolden_content on Query {
    ...SidebarVerificationSection_content
    site {
      menu {
        search {
          gameListId
        }
      }
    }
  }
`;

const quickDepositFormQuery = graphql`
  query SidebarGoldenQuery {
    ...QuickDepositForm_content
  }
`;

const Nav = styled.nav<{ isOpen: boolean }>(({ isOpen }) => ({
  position: 'fixed',
  width: '100%',
  top: DynamicValues.GamePauseHeight,
  bottom: 0,
  left: 0,
  zIndex: ZIndex.MainMenu,
  transition: 'transform 0.2s',
  transform: isOpen ? undefined : `translateX(-100%)`,
  color: SidebarColors.Text,
  '::-webkit-scrollbar': {
    display: 'none',
  },
  [Breakpoint.LaptopOrLarger]: {
    transform: 'translateX(0)',
    background: SidebarColors.Background,
    borderRight: SidebarColors.Border,
    width: LayoutProps.SidebarWidthThin,
    '::-webkit-scrollbar': {
      display: 'unset',
    },
  },
}));

const NavWrapper = styled.div({
  position: 'static',
  overflow: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: '100%',
  background: SidebarColors.Background,

  '::-webkit-scrollbar': {
    width: '0.3rem',
    height: '0.3rem',
  },
});

const SidebarHeader = styled.header({
  position: 'relative',
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: 24,
  overflow: 'hidden',
  background: SidebarColors.SidebarTopBackground,

  // radial blur
  '::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    margin: '0 auto',
    width: '24rem',
    height: '24rem',
    display: 'block',
    backgroundImage: 'radial-gradient(circle closest-side, #1ABFC6 0%, transparent 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    transform: 'translateY(-66%) scaleY(1.5)',
    opacity: 0.25,
    pointerEvents: 'none',
  },
});

const SidebarHeaderLogo = styled(Link)({
  marginBottom: 24,
  zIndex: 1,

  '> img': {
    width: '100%',
    maxWidth: 176,
    height: 'auto',
  },

  '> svg': {
    height: 110,
  },
});

const SidebarIconButton = styled(HeaderIconButton)({
  position: 'absolute',
  zIndex: 1,
  [Breakpoint.LaptopOrLarger]: {
    display: 'none',
  },
});

const SidebarContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  paddingBottom: 32,
  borderTop: SidebarColors.Border,
  background: SidebarColors.SidebarContent,
});

const DepositHeading = styled(Heading)({
  fontSize: FontTextSize.Small,
  color: SidebarColors.Text,
});

const SidebarDepositToPlayForm = styled(DepositToPlayForm)({
  '--quick-button-text': SidebarColors.DepositQuickButtons,
  '--quick-button-text--hover': SidebarColors.DepositQuickButtonsHover,
});

const MyWalletLink = styled(ButtonLink)({
  marginTop: 24,
  minHeight: 'unset',
  padding: '0.5em 1.25em',

  span: {
    fontSize: '1.2em',
  },
});

const SidebarSearchContainer = styled.div({
  paddingTop: 16,
  paddingInline: 8,
});

const BonusContainer = styled(BonusbarContainer)({
  margin: '16px auto',
  flexDirection: 'column',

  progress: {
    position: 'relative',
    marginBottom: 8,
  },
  label: {
    color: 'currentColor',
  },
});

const BonusProgressBar: FC = () => {
  const { t } = useTranslation('wallet');
  const { currentTurnover, totalTurnover } = useWallet();
  const { turnover } = useBonus();
  const { openFlow } = useFlowRouter();

  if (turnover?.step == null || ('status' in turnover.step && turnover.step.status !== 'ACTIVE')) {
    return null;
  }

  const launchBonusFlow = () => {
    openFlow('bonus', {
      playerProgramId: turnover.offerId,
    });

    return null;
  };

  return (
    <BonusContainer>
      <ProgressBar
        id="bonus-progress"
        aria-valuemax={totalTurnover ?? 0}
        aria-valuenow={currentTurnover ?? 0}
        max={totalTurnover ?? 0}
        value={currentTurnover ?? 0}
        tabIndex={0}
        onClick={() => launchBonusFlow()}
        onKeyDown={(event) => event.code === 'Enter' && launchBonusFlow()}
      />
      <label htmlFor="bonus-progress">{t('progress-bar.turnover.text')}</label>
    </BonusContainer>
  );
};

const SidebarWallet = styled(WalletComponent)({
  margin: 0,
});

const SidebarWalletContainer = styled.div({
  marginBottom: 16,
});

const WalletToggleButton = styled(Button)({
  border: 0,
  background: 'none',
  margin: '0 auto',

  ':active, :focus': {
    boxShadow: 'none',
    background: 'var(--button-background--hover)',
  },
});

const Wallet: FC = () => {
  const [showWallet, setShowWallet] = useStoredState('showSidebarWallet', true);

  const toggleWallet = () => {
    setShowWallet(!showWallet);
  };

  return (
    <SidebarWalletContainer>
      <WalletToggleButton
        type="button"
        variant="secondary"
        colorstyle="accent"
        onClick={toggleWallet}
        role="switch"
        aria-checked={showWallet}
      >
        {showWallet ? <Icon name="toggleSee" /> : <Icon name="toggleUnsee" />}
      </WalletToggleButton>
      {showWallet && <SidebarWallet session={null} />}
    </SidebarWalletContainer>
  );
};

const Login = styled(ButtonLink)({
  marginTop: 24,

  '--button-text': SidebarColors.EmphasizedLink,
  '--button-border': 'transparent',
  '--button-border--hover': SidebarColors.EmphasizedLink,
  '--button-background': 'transparent',
  '--button-background--hover': 'transparent',
  '--button-text--hover': SidebarColors.EmphasizedLink,
});

const LoginButton: FC = () => {
  const { t } = useTranslation('header');
  const { getFlowUrl } = useFlowRouter();

  return (
    <Login
      href={getFlowUrl('login')}
      type="button"
      variant="secondary"
      colorstyle="accent"
      size="default"
      aria-label={t('login-button.aria-label')}
    >
      {t('login-button.text')}
    </Login>
  );
};

const Logotype: FC = () => {
  if ($buildEnv.site === 'noaccountbet.se') {
    return <Image src="/static/images/nab-logo-vertical.svg" alt="" width={810} height={481} unoptimized />;
  }

  if ($buildEnv.site === 'noaccountcasino.se') {
    return <Image src="/static/images/no_account_casino_bull.png" alt="" width={400} height={365} />;
  }

  if ($buildEnv.site === 'goldenbull.es') {
    return <Image src="/static/images/goldenbull-logo.png" alt="" width={400} height={309} />;
  }

  if ($buildEnv.site === 'goldenbull.se') {
    return <Image src="/static/images/goldenbull-logo.png" alt="" width={400} height={309} />;
  }

  return <Logo />;
};

export const Sidebar = forwardRef<HTMLElement, SidebarProps>(({ onClose, isOpen, children, ...props }, ref) => {
  const content = useFragment(contentFragment, props.content);
  const { t } = useTranslation('deposit');
  const { getFlowUrl } = useFlowRouter();
  const isLoggedIn = useIsLoggedIn();
  const config = useConfig();

  return (
    <Nav isOpen={isOpen} ref={ref} data-testid="main-menu">
      <SidebarIconButton onClick={onClose}>
        <Burger open />
      </SidebarIconButton>
      <NavWrapper>
        <SidebarHeader>
          <SidebarHeaderLogo href="/">
            <Logotype />
          </SidebarHeaderLogo>

          {isLoggedIn ? (
            <>
              <Wallet />
              <BonusProgressBar />
              {!config.hasQuickDeposit && (
                <ButtonLink
                  href={getFlowUrl('deposit')}
                  variant="primary"
                  colorstyle="accent"
                  size="large"
                  type="button"
                  ctaEffect
                  fullWidth
                >
                  {t('choose-amount.button.text')}
                </ButtonLink>
              )}
              {config.hasQuickDeposit && (
                <SafeQueryRenderer<SidebarGoldenQuery>
                  query={quickDepositFormQuery}
                  render={(data) => <QuickDepositForm sidebar={true} data={data.props}></QuickDepositForm>}
                />
              )}
              <MyWalletLink
                variant="secondary"
                colorstyle="accent"
                href="/profile"
                aria-label={t('account.button.aria-label')}
                icon="wallet"
              >
                {t('account.button.text')}
              </MyWalletLink>
            </>
          ) : (
            <>
              <DepositHeading>{t('choose-amount.heading')}</DepositHeading>
              <SidebarDepositToPlayForm submitButtonText={t('choose-amount.button.text')} />
              <LoginButton />
            </>
          )}
        </SidebarHeader>

        <SidebarVerificationSection content={content} />

        <SidebarContent>
          {content.site.menu?.search.gameListId && (
            <SidebarSearchContainer>
              <SidebarSearch gameListId={content.site.menu.search.gameListId} />
            </SidebarSearchContainer>
          )}
          {children}
        </SidebarContent>
      </NavWrapper>
    </Nav>
  );
});

Sidebar.displayName = 'Sidebar';
