import { PMLColor } from '@pafcloud/style';

export const Colors = {
  Text: PMLColor.Lighter,
  DepositQuickButtons: PMLColor.Primary,
  DepositQuickButtonsHover: PMLColor.PrimaryHighlight,
  Border: `1px solid ${PMLColor.AlternateTransparent25}`,
  Background: PMLColor.Background,
  SidebarContent: PMLColor.Background,
  SidebarTopBackground: PMLColor.CardBackground,
  SidebarOverlay: undefined,
  EmphasizedLink: PMLColor.Alternate,
};
